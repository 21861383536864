<template>
  <div class="comment-card">
    <div class="d-flex">
      <img
        v-if="comment.agencyUser.avatar.length > 0"
        class="comment-avatar"
        :src="$getFileURL(comment.agencyUser.avatar[0])"
        :alt="comment.agencyUser.name"
        @error="setDefaultAvatar"
      />
      <img
        v-else
        class="comment-avatar"
        src="~@/assets/image/placeholder/default-avatar.svg"
        :alt="comment.agencyUser.name"
      />
      <div class="ml-1">
        <div>
          <p class="d-inline-block bold">{{ comment.agencyUser.name }}</p>
          <span v-if="showStatus" class="d-inline-block status">{{
            comment.status
          }}</span>
        </div>
        <p class="whitespace-prewrap">
          {{ comment.content }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { setDefaultAvatar } from "@/utils/image";

export default {
  components: {},
  mixins: [],
  props: {
    comment: {
      type: Object,
      required: true
    },
    showStatus: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      setDefaultAvatar: setDefaultAvatar
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.comment-card {
  img.comment-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  span.status {
    background: $color-main;
    color: white;
    padding: 4px 10px;
    font-size: 12px;
    border-radius: 4px;
    margin-left: 10px;
  }
}
</style>
